import "mind-ar/dist/mindar-face.prod.js";
import "aframe";
import "mind-ar/dist/mindar-face-aframe.prod.js";
import MindARViewer2 from "./mindar-viewer2.js";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import db from "../firebase";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import useAnalytics from "../hooks/useAnalytics";
import useScore from "../hooks/useScore";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import { DataContext } from "./DataContext";

export default function FaceAR() {

	const {DATA, setData} = useContext(DataContext);

	const location = useLocation();
	const { data } = location.state;
	const { packet_id } = location.state;
	const { points } = location.state;

	const { activity_i } = useParams();
	const activity_index = activity_i - 1;

	const packet_index = parseInt(packet_id.replace(/packet_/,'')-1);

	const [package_points, setPackage_points] = useState(0);

	const { user, setUser } = useContext(UserContext);
	//const [points, setPoints] = useState(0);
	const [popUp, setPopUp] = useState(false);
	const [popUpMessage, setPopUpMessage] = useState(null);
	const [fire, setFire] = useState(false);
	const id = Cookies.get().id;
	const navigate = useNavigate();
	const [addAnalytics] = useAnalytics("ar mint", id, {}, user.project_id);
	const [packagePoints, setPackagePoints] = useState(0);
	const [userScore, setUserScore] = useState(0);
	const update = async () => {
		if (user && user.progress && user.project_id) {
			console.log('DEBUG - Update function:', {
				DATA,
				packet_index,
				activity_index,
				user_project_id: user.project_id
			});
			
			const user_ref = await doc(db, "users", Cookies.get().id);

			// Create a deep copy of the user object to avoid reference issues
			const updatedUser = JSON.parse(JSON.stringify(user));

			// Mark activity as completed
			if (!updatedUser.progress[user.project_id]['packets'][packet_index].activities) {
				updatedUser.progress[user.project_id]['packets'][packet_index].activities = [];
			}

			// Get activity data from DATA if it exists
			const activityData = DATA?.['PACKETS']?.[packet_index]?.activities?.[activity_index];
			
			// Ensure the activity exists at the specified index
			if (!updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index]) {
				updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index] = {
					id: activity_index.toString(),
					name: activityData?.name || 'AR Activity',
					completed: false
				};
			}

			// Update the activity
			updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
			
			// Properly calculate points using explicit integer conversion
			const currentPackagePoints = parseInt(package_points) || 0;
			const pointsToAdd = parseInt(points) || 0;
			updatedUser.progress[user.project_id]['packets'][packet_index].package_points = currentPackagePoints + pointsToAdd;

			// Handle rewards if they exist
			if (activityData?.reward) {
				let reward = activityData.reward;
				if (reward.name !== '') {
					updatedUser.rewards.push({
						name: reward.name,
						price: reward.price,
						image: reward.image,
						redeemed: false,
						instruction: reward?.instruction,
					});
				}
			}

			// Check if all activities in this module are completed
			const allActivitiesCompleted = updatedUser.progress[user.project_id]['packets'][packet_index].activities.every(
				activity => activity.completed
			);

			if (allActivitiesCompleted) {
				// Mark current module as completed
				updatedUser.progress[user.project_id]['packets'][packet_index].packet_completed = true;

				// Unlock next module if it exists
				const nextPacketIndex = packet_index + 1;
				if (updatedUser.progress[user.project_id]['packets'][nextPacketIndex]) {
					updatedUser.progress[user.project_id]['packets'][nextPacketIndex].packet_activated = true;
					updatedUser.progress[user.project_id]['packets'][nextPacketIndex].badge.is_unlocked = true;
				}
			}

			// Update progress flag
			updatedUser.setprogress = false;

			// Update local state
			setUser(updatedUser);

			// Calculate new total score properly
			const currentScore = parseInt(userScore) || 0;
			const newScore = currentScore + pointsToAdd;

			// Update Firebase
			await updateDoc(user_ref, {
				progress: updatedUser.progress,
				rewards: updatedUser.rewards,
				score: newScore
			});
		}
	};

	useEffect(() => {

		if (user.progress && user.project_id && user.progress[user.project_id]) {

			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
		}
	}, [user, user.progress, user.project_id]);

	function onModalChange(newState) {   
		setPopUp(newState);
	}

	useEffect(() => {
		if (location.protocol != "https:") {
			location.href =
				"https:" +
				window.location.href.substring(window.location.protocol.length);
		}
		function permission() {
			if (
				typeof DeviceMotionEvent !== "undefined" &&
				typeof DeviceMotionEvent.requestPermission === "function"
			) {
				// (optional) Do something before API request prompt.
				DeviceMotionEvent.requestPermission()
					.then((response) => {
						// (optional) Do something after API prompt dismissed.
						if (response == "granted") {
							window.addEventListener("devicemotion", (e) => {
								// do something for 'e' here.
							});
						}
					})
					.catch(console.error);
			} else {
				alert("DeviceMotionEvent is not defined");
			}
		}
		const btn = document.querySelector("iframe");
		btn.addEventListener("click", permission);

		setTimeout(() => {
			if (document.querySelector(".button")) {
				document.querySelector(".button").classList.add("ready");
			}
			var id = window.setTimeout(function () {}, 0);
			while (id--) {
				window.clearTimeout(id); // will do nothing if no timeout with id is present
			}
		}, 8000);

		document.querySelector(".button").addEventListener("click", async (event) => {
			document.querySelector(".button").classList.remove("ready");
			setPopUpMessage("You just unlocked Module Two!");
			setPopUp(true);
			setFire(true);
			// updateScore();
			await addAnalytics();
			update();
			document.body.style.overflow = "visible";
		});
	});

	useEffect(() => {
		if (user.progress) {
			//const { points } = location.state;

			if (user.progress && user.project_id) {
				setPackagePoints(user.progress[user.project_id]['packets'][packet_index].package_points);
			}

			setUserScore(user.score);
		}
	}, [user.progress]);

	//console.log(data);

	//https://app.aryel.io/d829c34f-b22a-4d4d-95a2-66394525d1f1/christmas-face-filter-496239372

	return (
		<section id="aryel" className="w-screen h-screen top-0 overflow-hidden">
			<button id="request">Request Permissions</button>
			<iframe
				allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;"
				src={data.arUrl}
				className="aryel"
			/>
			<a className="button">Complete</a>
			<ConfettiComp fire={fire} />
			<PopUpMessage onModalChange={onModalChange} trigger={popUp}>
				{
					<div>
						<h2>Looks like you've made a new metallic friend!</h2>
						{/* <img src={user.avatarUrl}></img> */}
					</div>
				}
			</PopUpMessage>
		</section>

		// <div className="ar">
		// 	<MindARViewer2 points={points} />
		// 	<video></video>
		// </div>
	);
}
