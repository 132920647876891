import { react, useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import useCookie from "../hooks/useCookie";
import { DataContext } from "./DataContext";
import ArrowLeft from "./ArrowLeft";
import PopUpBio from "./PopUpBio";
import ConfettiComp from "./ConfettiComp";
import ProgressBar2 from "./ProgressBar2";
import { addDoc, orderBy, query } from "firebase/firestore";
import { doc, onSnapshot, updateDoc, collection } from "@firebase/firestore";
import db from "../firebase";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { ProgressCircleComponent } from '@insertcoinab/gwen-react-components';


export default function Rewards() {
    
    const {DATA, setData} = useContext(DataContext);
    
	const { user, setUser } = useContext(UserContext);
	const [score, setScore] = useState(0);
	const [userAvatar, setUserAvatar] = useState("/img/avatar-1.png");
	const [name, setName] = useState("");
	const [rewards, setRewards] = useState([]);
	const navigate = useNavigate();

	const [classChange, setClassChange] = useState(false);
	const [popupText, setPopupText] = useState("");
	const [popup, setPopup] = useState(false);
	const [popupAdd, setPopupAdd] = useState("");
	const [popupEmoji, setPopupEmoji] = useState("");
	const [popupHeading, setPopupHeading] = useState("");
	const [popupContent, setPopupContent] = useState("");
	const [fire, setFire] = useState(false);
	const [showDetails, setShowDetails] = useState(false);

	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		let unsubscribe = () => {};
		const userId = Cookies.get().id;

		if (userId) {
			const userRef = doc(db, "users", userId);
			unsubscribe = onSnapshot(userRef, (doc) => {
				if (!isMounted.current) return;
				
				if (doc.exists()) {
					const userData = doc.data();
					setUser(userData);
					setScore(parseInt(userData.score) || 0);
					setUserAvatar(userData.avatarUrl || "/img/avatar-1.png");
					setName(userData.firstName || "");
					const newRewards = userData.rewards || [];
					if (JSON.stringify(rewards) !== JSON.stringify(newRewards)) {
						setRewards(newRewards);
					}
				}
			});
		}

		return () => {
			unsubscribe();
		};
	}, []);

	const handleClick = async (e, id, name, image, price, instruction) => {
		if (!isMounted.current) return;

		setPopupContent(image);
		setPopupHeading(name);
		setClassChange(!classChange);
		setPopupText(instruction);

		setPopupAdd(
			(user.score >= price &&
				id === "reward-one" &&
				!user.rewards.reward_one.redeemed) ||
				(user.score >= price &&
					id === "reward-two" &&
					!user.rewards.reward_two.redeemed) ? (
				<button
					className="redeem-btn"
					onClick={async () => {
						if (!isMounted.current) return;

						setFire(true);
						const timeoutId = setTimeout(() => {
							if (isMounted.current) {
								setPopup(false);
							}
						}, 1500);

						try {
							const user_ref = doc(db, "users", user.id);
							await updateDoc(user_ref, {
								"rewards.reward_one.redeemed": price === 25,
								"rewards.reward_two.redeemed": price !== 25
							});
							
							if (isMounted.current) {
								navigate("/profile");
							}
						} catch (error) {
							console.error("Error updating rewards:", error);
						}

						return () => clearTimeout(timeoutId);
					}}
				>
					Redeem
				</button>
			) : (
				""
			)
		);
	};

	function onModalChange(newState) {   
		setPopup(newState);
	}

	useEffect(() => {
		if (!isMounted.current) return;
		setPopup(classChange);
	}, [classChange]);

	return (
		<div className="rewards">
			<header>
				<div className="top">
					<ArrowLeft />
					Rewards
				</div>
			</header>
			<div className="profile-wrapper">
				{/* <div className="dash"></div> */}
				<div className="rewards-header">
					<div className="rewards-header-flex">
						{user.avatarRevealed ? (
							<div style={{
								width: '100px', margin: '0 24px 24px 24px',
								transform: 'translateY(15px)'
							}}>
								<ProgressCircleComponent
									currentStep={score}
									step={score}
									shape="shield"
									progress={score / 50}
									avatar={userAvatar}
									stroke={{ primary: '#a57af7', background: '#6946aa' }}
									style={{ margin: '0 20px' }}
								/>
							</div>
						) : (
							<div style={{ width: '100px', margin: '0 24px 24px 24px', transform: 'translateY(15px)' }}>
								<ProgressCircleComponent
									currentStep={score}
									step={score}
									shape="shield"
									progress={score / 50}
									avatar={userAvatar}
									stroke={{ primary: '#a57af7', background: '#6946aa' }}
									style={{ margin: '0 20px' }}
								/>
							</div>
						)}
						<div className="points-box">
							<div className="points-centered">
								<p className="points-text">{score} Points</p>
								<p className="points-caption">Reward balance</p>
							</div>

						</div>
					</div>
				</div>

				<div className="rewards-body">
					<p className="profile-titles">Available rewards </p>


					<div className="reward">
						{Object.values(rewards).map((reward, i) => {
							
							if (reward.price <= user.score) {
								const img = reward.image;
								return (
									<div
										key={i}
										className="wallet-card activity-nft"
										onClick={(e) => handleClick(e, i, reward.name, reward.image, reward.price, reward.instruction)}
										>
										<img className="emoji" src={img} />
										<div className="reward-card-right">
											<p>{reward.name}</p>
											<p className="price-in-points">{reward.price} points</p>
											<p className="instruction">{reward?.instruction}</p>
											{/* onClick={(e) => handleClick(e, i, reward.name, reward.price)} */}
											{/* <button className={`business-card-btn redeem-btn ${i == 0 ? "visible" : "hidden"} ${reward.redeemed ? "disabled" : "active"}`} onClick={(e) => handleClick(e, i, reward.name, reward.price)}>{reward.redeemed ? "Redeemed" : "Redeem"}</button>
 */}


										</div>
									</div>
								);
							} else {
								const img = reward.image;
								return (
									<div
										key={i}
										className="locked wallet-card activity-nft"
									>
										<img className="emoji" src={img} />
										<div className="reward-card-right">
											<p>{reward.name}</p>
											<p className="price-in-points">{reward.price} points to unlock</p>
											<p className="instruction">{reward?.instruction}</p>
										</div>
									</div>
								);
							}
						})}
					</div>
				</div>

			</div>
			<PopUpBio trigger={popup} onModalChange={onModalChange}>
				{
					<>
						<div
							className={`wallet-card ${!showDetails ? "" : "hide-details"}`}
							onClick={(e) => handleClick(e, "")}
						>
							{popupContent ? (
								<img className="wallet-image" src={popupContent} alt="" />
							) : (
								<div className="emoji">{popupEmoji}</div>
							)}
						</div>
						<div className={`flip-side ${!showDetails ? "" : "hide-details"}`}>
							<h2>{popupHeading} </h2>
							<div className="popup-text">{popupText}</div>
							{popupAdd}
						</div>
						<button
									className="redeem-btn"
									onClick={(e) => {
										setPopup(false)
										// code for redemption
									}}
								>
									OK
								</button>
					</>
				}
			</PopUpBio>
			<ConfettiComp fire={fire} />
			<Footer />
		</div>
	);
}